<template>
    <input type="text" inputmode="decimal" :placeholder="placeholder" v-model="input" @keydown="prevent" />
</template>

<script>
export default {
    props: {
        modelValue: {
            type: [String, Number]
        },
        min: {
            type: [Number, String]
        },
        max: {
            type: [Number, String]
        },
        decimalLimit: {
            type: [Number]
        },
        placeholder: String
    },
    emits: ['update:modelValue'],
    data() {
        return {
            inputValue: this.modelValue
        }
    },
    computed: {
        input: {
            get() {
                return this.inputValue
            },
            set(value) {
                value = this.parseValue(value)

                this.inputValue = value

                if (value === null || isNaN(value)) {
                    this.$emit('update:modelValue', null)
                } else {
                    value = value.toString()
                    this.$emit('update:modelValue', value)
                }
            }
        }
    },
    watch: {
        modelValue: function (newVal) {
            if(Number(newVal) !== Number(this.inputValue)) this.inputValue = newVal
        }
    },
    methods: {
        parseValue(value) {
            if (value === '') return null
            if (value === ',' || value === '.') return '0.'
            value = value.replace(/,/g, '.')
            return value
        },
        prevent(e) {
            const input = e.target.value;

            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                return;
            }

            if ((e.key === ',' || e.key === '.') && (input.includes('.') || input.includes(','))) {
                return e.preventDefault();
            }

            if (!/^[0-9]$/i.test(e.key)) {
                const isCommandOrCtrlPressed = e.metaKey || e.ctrlKey;

                switch (e.key) {
                    case 'Home':
                    case 'End':
                    case 'Shift':
                    case 'Control':
                    case 'Escape':
                    case 'Alt':
                    case 'Meta':
                    case 'Tab':
                    case 'Backspace':
                    case 'Delete':
                    case 'Enter':
                    case '.':
                    case ',':
                    case 'ArrowLeft':
                    case 'ArrowRight':
                        break;
                    case 'v':
                    case 'c':
                    case 'x':
                        if (!isCommandOrCtrlPressed) {
                            console.log(`Prevent key: ${e.key}`);
                            e.preventDefault();
                        }
                        break;
                    default:
                        console.log(`Prevent key: ${e.key}`);
                        e.preventDefault();
                }
            }
        }
    }
}
</script>

<style scoped>
input {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 10px 10px;
    padding-right: 0;
    font-size: 1.3rem;
    font-weight: 600;
    flex: 1;
    min-width: 10px;
    width: 100px;
    font-family: din-2014, sans-serif;
}
input::placeholder {
    color: rgba(255, 255, 255, 0.4);
}
</style>